import React from "react"
import { CodeBlockDarkTheme } from "../utils"
import { Code, CodeBlock } from "@atlaskit/code"
// @ts-ignore
import WebhookCreatePreviewImg from '../../static/images/webhooks-create-preview.png';
// @ts-ignore
import WebhookFailedReasonImg from '../../static/images/webhooks-failed-reason.png';
// @ts-ignore
import WebhookRetryImg from '../../static/images/webhooks-automatic-retry.png';
import Endpoint from "../components/Layouts/Resources/Endpoint"

const styles = require("./Pages.module.scss");

export default function Webhooks() {
  return (
    <React.Fragment>
      <div className={styles.Content}>
        <h1>Webhooks</h1>

        <p>Webhooks allow your server to receive a POST request payload when a subscribed event happens in Prestavi. This means that you don’t need to continuously poll the API to check for updated data and instead you’ll instantly receive updates as they happen.</p>

        <h2>Creating a Webhook</h2>
        <p>To create a webhook, log in to your Prestavi account and navigate to <strong>Manage &gt; Developers &gt; Webhooks</strong>. Choose the <strong>New Webhook</strong> button.</p>
      </div>

      <div className={styles.UnboundedContent}>
        <img style={{margin: "32px 0", maxWidth: "900px"}} src={WebhookCreatePreviewImg} />
      </div>

      <div className={styles.Content}>
        <section>
          <p>You can choose to configure the webhook with the payload data, headers, and additional custom properties that you need. </p>

          <p>Additional information about webhooks:</p>
          <ul>
            <li>The request URL to your server must use HTTPS for security.</li>
            <li>We currently only support triggering a webhook via an action in a workflow.</li>
          </ul>
        </section>

        <section>
          <h2>Handling Failures</h2>
          <p>We get it, sometimes processing a request doesn't go as planned. If you respond to the webhook request with a 500 error, the job action will receive a failed status.</p>

          <h3>Provide a Reason</h3>
          <p>If you provide a reason property with the response, then we’ll output that in the user interface for end users to know what happened. </p>

          <img src={WebhookFailedReasonImg} style={{maxWidth: "700px"}}/>

          <div style={{marginTop: "48px"}}></div>
          <h3>Automatic Retries</h3>
          <p>If you would like the request to be automatically retried, then make sure to choose the "Automatically Retry Failures" option and set a delay period and max attempt (10 maximum).</p>

          <img src={WebhookRetryImg} style={{maxWidth: "600px"}}/>
        </section>

        <section>
          <h2>How to Respond</h2>
          <p>The response the webhook request receives will determine the status of the action. The following response codes map to the corresponding action statuses</p>

          <div className={styles.Statuses}>
            <div className={styles.Status}>
              <p>200</p>
              <span style={{backgroundColor: "#b9e5d6", color: "#24624d"}}>Done</span>
            </div>
            <div className={styles.Status}>
              <p>202</p>
              <span style={{backgroundColor: "#cdedf7", color: "#12668a"}}>Wait</span>
            </div>
            <div className={styles.Status}>
              <p> &gt; 202</p>
              <span style={{backgroundColor: "#f7dede", color: "#ac2323"}}>Failed</span>
            </div>
          </div>
        </section>

        <section>
          <h2>Updating a Job Action Status</h2>
          <p>
            The webhook request payload will include a <Code text="meta" /> object that contains a <Code text="link" /> property. The link property is an API URL that you can use to send a <Code language="javascript" text="POST" /> request to update the status of the job action. This comes in handy when processing the request takes time (such as calling 3rd party APIs) and first you respond with a 202 status.
          </p>

          <Endpoint
            description={""}
            inline={true}
            endpoint={{
              verb: "POST",
              url: "<url provided in response meta.link>"
            }}
            body={[
              {
                name: "status",
                type: "enum",
                description: "The status to change the job action to. Options include done or failed."
              },
              {
                name: "reason",
                type: "string",
                description: "The message to show the user why the action failed. Required for a failed status."
              }
            ]}
            response={{
              "status": "ok",
              "code": 200,
              "data": {
                "jobActionKey": "ckom6txhs0001cao35b3hvdg1",
                "jobKey": "ckom6tu6x00033gg69n3vs83c"
              }
            }}
            breadcrumbs={null}
            />
        </section>
      </div>
    </React.Fragment>

  )
}